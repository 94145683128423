import React from 'react'
import cx from 'classnames'
import Heading from '../../atoms/Typography/Heading/Heading'
import styles from './Header.module.scss'

type TProps = {
  text: string
  icon?: React.ReactNode
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  trailingIcon?: React.ReactNode
  className?: string
}

const Header = ({ icon, tag = 'h1', text, trailingIcon, className = '' }: TProps) => {
  return (
    <div className={cx(styles.root, className)}>
      {icon && <i>{icon}</i>}
      <Heading as={tag} className={styles.heading}>
        {text}
      </Heading>
      {trailingIcon && <i>{trailingIcon}</i>}
    </div>
  )
}

export default Header
