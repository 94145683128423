import React from 'react'
import { subscribe } from 'react-contextual'
import { useSelector } from 'react-redux'
// import { find, pipe, prop, pathOr, head } from 'ramda'
import { Modal, Button } from '../../../../views/components'
import PortfoliosProvider from '../../PortfoliosProvider'
import { chatbotSelectors } from '../../../ui/ChatBot'
import styles from './ConfirmPortfolioActivation.module.scss'

const ConfirmPortfolioActivation = ({
  activateCurrentPortfolio,

  // rentInvoiceTemplateSchedule
  // invoiceSchedules,
  // invoiceTemplates
  declineActivationConfirmation,
}: any) => {
  // const { sendAt, dueDate } = rentInvoiceTemplateSchedule
  // const { sendAt } = rentInvoiceTemplateSchedule
  const isActivationConfirmationOpen = useSelector(chatbotSelectors.isActivationConfirmationOpen)
  return (
    <Modal
      isOpen={isActivationConfirmationOpen}
      theme="dark"
      onClose={declineActivationConfirmation}
      className={styles.root}
    >
      <h3 className="Modal_Title">Lease Activation Confirmation</h3>
      <div className="Modal_Content">
        <p>
          By activating this lease, reOS will action the tasks below. Please read carefully, you are able to cancel the
          activation to make changes.
        </p>
        <ul>
          {/* <li>Once-off invoices are sent and due immediately.</li> */}
          {/* <li>Monthly scheduled emails will start sending on {sendAt}</li> */}
          <li>The tenant is sent a lease activation email immediately.</li>
          <li>Once-off and monthly invoices are immediately generated and available for manual send.</li>
        </ul>
        <p>Do you want to activate this lease?</p>
        {/* <p>
          Monthly scheduled emails will start on {sendAt}.
        </p>
        <p>
          Do you want reOS to activate this lease?
        </p> */}
        <div className="Modal_Actions">
          <Button link onClick={activateCurrentPortfolio}>
            Yes, activate
          </Button>
          <Button link onClick={declineActivationConfirmation}>
            No, make changes
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default subscribe(
  [PortfoliosProvider],
  ({
    activateCurrentPortfolio,

    // rentInvoiceTemplateSchedule
    // invoiceSchedules,
    // invoiceTemplates
    declineActivationConfirmation,
  }: any) => ({
    activateCurrentPortfolio,
    declineActivationConfirmation,
    // rentInvoiceTemplateSchedule
    // invoiceSchedules,
    // invoiceTemplates
  }),
)(ConfirmPortfolioActivation)
