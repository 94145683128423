import React from 'react'
import PropTypes from 'prop-types'
import Button from '../../../atoms/Button/Button'
import Modal from '../../../molecules/Modal/Modal'

const propTypes = {
  isOpen: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onDiscard: PropTypes.func.isRequired,
}

const defaultProps = {
  isOpen: false,
}

const SaveChangesModal = ({ isOpen, onSave, onDiscard }: any) => {
  return (
    <Modal showClose={false} isOpen={isOpen}>
      <h3 className="Modal_Title">Review changes</h3>
      <div className="Modal_Content">
        <p>Before you go, would you like to save or discard the changes you made?</p>
      </div>
      <div className="Modal_Actions">
        <Button link onClick={onSave}>
          Save
        </Button>
        <Button link onClick={onDiscard}>
          Discard
        </Button>
      </div>
    </Modal>
  )
}

SaveChangesModal.propTypes = propTypes
SaveChangesModal.defaultProps = defaultProps

export default SaveChangesModal
