import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { pipe, pick, values, all } from 'ramda'
import { stringUtils } from '../../../../utils'
import Svg from '../../atoms/Svg/Svg'
import Modal from '../../molecules/Modal/Modal'
import Button from '../../atoms/Button/Button'
import Timer from '../../molecules/Timer/Timer'
import styles from './OtpVerification.module.scss'

type Props = {
  isOpen: boolean
  cellNumber?: string
  failed?: boolean
  onSubmit?: (pin: { pin: string }) => void
  onTimeOut?: () => void
  resend?: () => void
  onClose: () => void
}

type State = {
  pin: string
  input1: string
  input2: string
  input3: string
  input4: string
  input5: string
  input6: string
  hasTimedout: boolean
  errors: string[]
}

class OtpVerification extends Component<Props, State> {
  input1: any
  input2: any
  input3: any
  input4: any
  input5: any
  input6: any
  submit: any

  constructor(props: any) {
    super(props)
    this.state = {
      pin: '',
      input1: '',
      input2: '',
      input3: '',
      input4: '',
      input5: '',
      input6: '',
      hasTimedout: false,
      errors: [],
    }
  }

  componentDidUpdate(prevProps: any): void {
    const { isOpen } = this.props
    if (!prevProps.isOpen && isOpen) {
      // Wait for modal to be rendered before focusing input
      setTimeout(() => {
        this.input1?.focus()
      }, 200)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: any): void {
    if (nextProps.failed) {
      this.setState({
        pin: '',
        input1: '',
        input2: '',
        input3: '',
        input4: '',
        input5: '',
        input6: '',
      })
    }
  }

  isValid = (): boolean =>
    pipe(
      pick(['input1', 'input2', 'input3', 'input4', 'input5', 'input6']),
      values,
      all((val: any) => val !== ''),
    )(this.state)

  handleChange = (e: any): void => {
    const { name, value } = e.target

    const position = parseInt(name.slice(-1))
    const nextInput = `input${position + 1}`
    const previousInput = `input${position - 1}`

    if (value === '') {
      if (this[previousInput]) {
        this[previousInput]?.focus()
      }
    } else if (value.length > 0) {
      this.updatePin(position - 1, value.slice(-1))
      if (this[nextInput]) {
        this[nextInput]?.focus()
      }
    }
  }

  updatePin = (position: number, value: any): void => {
    const inputKey = `input${position + 1}`
    // @ts-expect-error
    this.setState({ [inputKey]: value.slice(-1) })
    this.setState({
      pin: stringUtils.replaceAt(this.state.pin, position, value),
    })
  }

  handleResend = (): void => {
    this.setState({ hasTimedout: false })
    if (typeof this.props.resend === 'function') {
      this.props.resend()
    }
    this.input1?.focus()
  }

  handleSubmit = (pin): void => {
    if (typeof this.props.onSubmit === 'function') {
      this.props.onSubmit({ pin })
    }
  }

  render(): null | React.ReactElement {
    const { isOpen, cellNumber, failed, onClose } = this.props

    const title = failed ? 'Try again' : 'Verification code'

    const { pin, hasTimedout } = this.state

    return (
      <Modal isOpen={isOpen} padding="md" theme="dark" onClose={onClose}>
        <div className={styles.root}>
          <Svg name="otp-shield-and-ring" />
          <h2 className={styles.title}>{title}</h2>
          <div className={styles.pin}>
            <input
              disabled={hasTimedout}
              name="input1"
              value={this.state.input1}
              ref={el => {
                this.input1 = el
              }}
              onChange={this.handleChange}
            />
            <input
              disabled={hasTimedout}
              name="input2"
              value={this.state.input2}
              ref={el => {
                this.input2 = el
              }}
              onChange={this.handleChange}
            />
            <input
              disabled={hasTimedout}
              name="input3"
              value={this.state.input3}
              ref={el => {
                this.input3 = el
              }}
              onChange={this.handleChange}
            />
            <input
              disabled={hasTimedout}
              name="input4"
              value={this.state.input4}
              ref={el => {
                this.input4 = el
              }}
              onChange={this.handleChange}
            />
            <input
              disabled={hasTimedout}
              name="input5"
              value={this.state.input5}
              ref={el => {
                this.input5 = el
              }}
              onChange={this.handleChange}
            />
            <input
              disabled={hasTimedout}
              name="input6"
              value={this.state.input6}
              ref={el => {
                this.input6 = el
              }}
              onChange={this.handleChange}
            />
          </div>

          <p className={styles.instruction}>
            {cellNumber ? (
              `Please enter the 6-digit verification code sent to ${cellNumber as string}.`
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    'Please request a QR code from the dev team. You need a 2FA app that supports 60 second OTPs. Download <a href="https://getaegis.app/" target="_blank">Aegis</a> or <a href="https://apps.apple.com/us/app/authenticator-app/id1538761576" target="_blank">Authenticator App</a>.',
                }}
              />
            )}
          </p>

          {cellNumber && (
            <div className={styles.timer}>
              <Timer duration={60} />
            </div>
          )}

          {!hasTimedout ? (
            <Button
              block
              disabled={!this.isValid()}
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: string; block: true; disabled: b... Remove this comment to see the full error message
              ref={(el: any) => {
                this.submit = el
              }}
              onClick={this.handleSubmit.bind(null, pin)}
            >
              Submit
            </Button>
          ) : (
            this.props.cellNumber && (
              <Button contrast block onClick={this.handleResend}>
                Resend
              </Button>
            )
          )}
        </div>
      </Modal>
    )
  }
}

export default OtpVerification
