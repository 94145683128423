import React, { Component } from 'react'
import classNames from 'classnames'
import ReactModal from 'react-modal'
import Close from '../../atoms/Svgs/Close'
import ModalTitle from './ModalTitle'
import ModalHeader from './ModalHeader'
import ModalBody from './ModalBody'
import styles from './Modal.module.scss'

type TProps = {
  isOpen: boolean
  theme?: 'light' | 'dark'
  width?: 'sm' | 'md' | 'lg'
  padding?: 'sm' | 'md' | 'lg'
  align?: 'left' | 'center' | 'right'
  height?: 'auto' | 'full'
  showClose?: boolean
  onClose?: () => void
  children: React.ReactNode
  className?: string
}

ReactModal.setAppElement('#root')

const Modal = ({
  isOpen,
  theme = 'light',
  width = 'sm',
  padding = 'sm',
  align = 'center',
  height = 'auto',
  showClose = true,
  onClose = () => {},
  children,
  className,
}: TProps) => {
  const overlayClasses = classNames([`Modal_Align--${align}`])

  const contentClasses = classNames([
    'Modal',
    `Modal_Theme--${theme}`,
    `Modal_Width--${width}`,
    `Modal_Padding--${padding}`,
    `Modal_Height--${height}`,
    className,
  ])

  return (
    <ReactModal
      shouldCloseOnOverlayClick
      ariaHideApp={false}
      isOpen={isOpen}
      className={contentClasses}
      closeTimeoutMS={280}
      overlayClassName={overlayClasses}
    >
      {showClose && (
        <span className={styles.close}>
          <Close width="32px" height="32px" onClick={onClose} />
        </span>
      )}
      {children}
    </ReactModal>
  )
}

Modal.Title = ModalTitle
Modal.Header = ModalHeader
Modal.Body = ModalBody

export default Modal
