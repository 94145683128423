import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { integrationsApiEvents, integrationsApiSelectors } from 'modules/api/integrations'
import { apiStateSelectors } from 'modules/api/apiState'
import { Button, Loader, Modal } from 'views/components'
import TpnConsumerInfoEntry from '../TpnConsumerInfoEntry/TpnConsumerInfoEntry'
import TpnConsumerModuleSelection from '../TpnConsumerModuleSelection/TpnConsumerModuleSelection'
import TpnConsumerReport from '../TpnConsumerReport/TpnConsumerReport'
import { fromCents } from 'utils/currency'
import styles from './TpnEnquiryModal.module.scss'
import { $TSFixMe } from 'types/ts-migrate'

type Props = {
  isOpen: boolean
  onClose(): void
}

const TpnEnquiryModal: FC<Props> = ({ isOpen, onClose }) => {
  const initialConsumerInfoState = {
    moduleList: [],
    firstName: '',
    surname: '',
    rsaId: true,
    nationalId: '',
    cellNumber: '',
    addressLine1: '',
    suburb: '',
    city: '',
    province: '',
    postalCode: '',
    dateOfBirth: '',
    enquiryContactName: '',
    enquiryContactEmail: '',
    creditAmount: '',
    enquiryContactNumber: '',
    enquiryReason: 40,
  }

  const dispatch = useDispatch()
  const [consumerInfo, setConsumerInfo] = useState(initialConsumerInfoState)
  const [consumerModules, setConsumerModules] = useState([])
  const [consumerModulesByName, setConsumerModulesByName] = useState([])
  const [consumerStep, setConsumerStep] = useState<number>(0)

  const handleConsumerInfoSave = (info: $TSFixMe): void => {
    setConsumerInfo(info)
    setConsumerStep(consumerStep + 1)
  }

  const handleSelectedModules = (modules: $TSFixMe): void => {
    setConsumerModules(modules)
  }

  const handleSelectedModulesByName = (modules: $TSFixMe): void => {
    setConsumerModulesByName(modules)
  }

  const handleOrderReports = () => {
    dispatch(
      integrationsApiEvents.tpnConsumerEnquiry_request({
        ...consumerInfo,
        moduleList: consumerModules,
        province: consumerInfo.province.replace(/\s/g, ''),
        creditAmount: fromCents(consumerInfo.creditAmount).toString(),
      }),
    )
  }

  const isLoading = useSelector(state =>
    apiStateSelectors.isLoading(state)([integrationsApiEvents.tpnConsumerEnquiry_request]),
  )

  const consumerEnquiryResponse = useSelector(integrationsApiSelectors.getConsumerEnquiryResponse)

  const displayReportSummary = () => {
    const isEnquiryResponseFirstValueEmpty = Object.values(consumerEnquiryResponse)[0] === ''
    consumerStep === 1 && !isEnquiryResponseFirstValueEmpty ? setConsumerStep(2) : setConsumerStep(1)
  }

  const displayConsumerStep = () => {
    switch (consumerStep) {
      case 0:
        return <TpnConsumerInfoEntry handleSave={handleConsumerInfoSave} />
      case 1:
        return (
          <TpnConsumerModuleSelection
            selectedModules={handleSelectedModules}
            selectedModulesByName={handleSelectedModulesByName}
            nationalId={consumerInfo.nationalId}
          />
        )
      case 2:
        return (
          <TpnConsumerReport
            personalInfo={[
              consumerInfo.firstName,
              consumerInfo.surname,
              consumerInfo.nationalId,
              consumerInfo.cellNumber,
            ]}
            selectedModulesByName={consumerModulesByName}
            enquiryResponse={consumerEnquiryResponse}
          />
        )
      default:
        break
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
        setConsumerStep(0)
      }}
      width={consumerStep === 1 ? 'md' : 'lg'}
      padding="sm"
    >
      <h3 className={styles['modal-header']}>
        {consumerStep === 2 ? 'Reports Summary' : consumerStep === 1 ? 'Available Reports' : 'Enquiry Details'}
      </h3>
      <div className={styles['modal-content']}>{isLoading ? <Loader /> : displayConsumerStep()}</div>
      <div className={styles.footer}>
        {consumerStep === 1 && (
          <Button
            onClick={() => {
              handleOrderReports()
              displayReportSummary()
            }}
          >
            <span className={styles.text}>Order reports</span>
          </Button>
        )}
      </div>
    </Modal>
  )
}

export default TpnEnquiryModal
