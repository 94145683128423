import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { agencyApiEvents } from '../../../api/agency'
import { apiStateSelectors } from '../../../api/apiState'
import { DimmerLoader, Modal } from '../../../../views/components'
import styles from './GroupRoyaltiesModal.module.scss'

type Props = {
  isOpen: boolean
  onClose: () => void
  agencyGroupName: string
  agencyGroupValue: string
  agencyId: string
  agencyName: string
}

const GroupRoyaltiesModal: FC<Props> = ({
  isOpen,
  onClose,
  agencyGroupName,
  agencyGroupValue,
  agencyId,
  agencyName,
}) => {
  const dispatch = useDispatch()

  const isLoading = useSelector(state => apiStateSelectors.isLoading(state)([agencyApiEvents.activateAgency_request]))

  const handleConfirm = () => {
    if (agencyGroupValue === 'none') {
      dispatch(
        agencyApiEvents.removeFromGroup_request({
          params: {
            groupId: '754cceb6-f295-4d05-85c8-9d7ca4edeb1b',
            agencyId,
          },
        }),
      )
    } else {
      dispatch(
        agencyApiEvents.addToGroup_request({
          params: {
            groupId: '754cceb6-f295-4d05-85c8-9d7ca4edeb1b',
          },
          body: {
            agencyId,
            percentRoyalty: 11.5,
          },
        }),
      )
    }
    onClose()
  }

  return (
    <Modal className={styles.root} isOpen={isOpen} padding="sm" width="sm" theme="dark" showClose={false}>
      <Modal.Header title="Are you sure?" />
      <Modal.Body
        actions={[
          { label: `Confirm`, onClick: handleConfirm },
          { label: 'Cancel', onClick: onClose },
        ]}
      >
        <DimmerLoader loading={isLoading} bgColor="dark">
          <>
            <div className={styles['sub-header']}>
              {agencyGroupValue === 'none' ? (
                <>You are about to remove {agencyName} from all groups</>
              ) : (
                <>
                  You are about to add {agencyName} to {agencyGroupName}
                </>
              )}
            </div>
          </>
        </DimmerLoader>
      </Modal.Body>
    </Modal>
  )
}

export default GroupRoyaltiesModal
