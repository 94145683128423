import React from 'react'
import { Modal, Button } from 'views/components'
import styles from './RevokeUserConfirmationModal.module.scss'

type Props = {
  isOpen: boolean
  onRevoke: () => void
  onCancel: () => void
}

const RevokeUserConfirmationModal = ({ isOpen, onRevoke, onCancel }: Props) => {
  return (
    <Modal className={styles.root} isOpen={isOpen} padding="sm" width="sm" theme="light" showClose={false}>
      <Modal.Header title="Revoke Access" />
      <Modal.Body>
        <>
          <div className={styles['sub-header']}>
            <p>Are you sure you want to revoke access for this user?</p>
          </div>
        </>
        <div className="Modal_Actions">
          <Button key="Yes" link onClick={onRevoke}>
            Yes
          </Button>
          <Button key="Cancel" link onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default RevokeUserConfirmationModal
