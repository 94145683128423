import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, path } from 'ramda'
import { FastField, Formik } from 'formik'
import * as yup from 'yup'
import {
  Modal,
  CurrencyField,
  FormField,
  Checkbox,
  RadioGroup,
  Radio,
  TextField,
  CurrencyText,
  FormLoader,
  TextInput,
} from '../../../../views/components'
import { currencyUtils } from '../../../../utils'
import { walletApiConstants, walletApiEvents } from '../../../api/wallet'
import styles from './DepositTransferModal.module.scss'
import { useDispatch } from 'react-redux'
import { debounce } from 'lodash-es'
import { TextFieldTypes } from 'views/components/atoms/TextField/text-field.types'

const { transferReasons } = walletApiConstants

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  contactName: PropTypes.string,
  partyId: PropTypes.string,
  account: PropTypes.string,
  asset: PropTypes.string,
  onTransfer: PropTypes.func,
  depositAccountBalance: PropTypes.number,
  propertyAddress: PropTypes.string,
  isLoading: PropTypes.bool,
  isSubmitting: PropTypes.bool,
}

const validationSchema = yup.object().shape({
  amount: yup
    .number()
    .required()
    .test('amount', function (amount) {
      if (amount && amount > this.parent.depositAccountBalance) {
        return this.createError({
          path: this.path,
          message: `can't exceed balance of ${currencyUtils.formatCurrency(this.parent.depositAccountBalance)}`,
        })
      }
      return true
    }),
  transferReason: yup
    .object()
    .shape({
      type: yup.string().required('required'),
      reason: yup.string(),
    })
    .test('transferReason', function (transferReason) {
      if (transferReason.type === transferReasons.other && !transferReason.reason?.trim()) {
        return this.createError({
          path: `${this.path}.reason`,
          message: `Reason is required when selecting other`,
        })
      }
      return true
    })
    .required(),
  isConfirmed: yup.bool().oneOf([true], 'Required!'),
})

const ButtonContainer = ({ children }: any): React.ReactElement => (
  <div className={styles['button-container']}>{children}</div>
)

const DepositTransferModal = ({
  isOpen,
  onClose,
  contactName,
  propertyAddress,
  partyId,
  account,
  asset,
  depositAccountBalance,
  onTransfer,
  isLoading,
  isSubmitting,
}: any): React.ReactElement => {
  const dispatch = useDispatch()

  const handleClose = useCallback(
    resetForm => {
      resetForm()
      onClose()
    },
    [onClose],
  )

  useEffect(() => {
    isOpen && dispatch(walletApiEvents.balance_request({ partyId }))
  }, [isOpen, dispatch, partyId])

  const [wasSubmitting, setWasSubmitting] = useState(false)

  useEffect(() => {
    if (isSubmitting) {
      setWasSubmitting(true)
    }
  }, [isSubmitting])

  const debouncedClose = useCallback(
    debounce(
      () => {
        onClose()
        setWasSubmitting(false)
      },
      1000,
      { leading: false },
    ),
    [onClose, setWasSubmitting],
  )

  /**
   * @todo error handling
   */
  useEffect(() => {
    if (wasSubmitting) {
      debouncedClose()
    }
  }, [wasSubmitting, onClose, debouncedClose])

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{
        amount: '',
        transferReason: { type: '' },
        isConfirmed: false,
        partyId,
        account,
        asset,
        depositAccountBalance,
      }}
      onSubmit={values => onTransfer({ body: values, params: { partyId: values.partyId } })}
    >
      {({ values, errors, touched, submitCount, handleSubmit, handleBlur, handleChange, setFieldValue, resetForm }) => {
        const handleOtherReason = ({ target }: any): void => {
          setFieldValue('transferReason', {
            reason: target.value,
            type: 'Other',
          })
        }

        const formLoaderState = isLoading
          ? 'loading'
          : isSubmitting
          ? 'submitting'
          : !isEmpty(errors)
          ? 'error'
          : undefined

        const formattedAmount: number = currencyUtils.formatCurrency(parseInt(values?.amount || '0'))

        return (
          <Modal isOpen={isOpen} onClose={() => handleClose(resetForm)}>
            <Modal.Title>Deposit Transfer</Modal.Title>
            <Modal.Body>
              <FormLoader
                onSubmit={handleSubmit}
                state={formLoaderState}
                buttonProps={{ children: 'Transfer' }}
                ButtonContainer={ButtonContainer}
              >
                <FormField>
                  <FastField
                    name="amount"
                    render={({ field }: any) => (
                      <CurrencyField
                        {...field}
                        label={
                          <span>
                            Amount (Balance: <CurrencyText>{values.depositAccountBalance}</CurrencyText>)
                          </span>
                        }
                        error={path(['amount'], touched) && path(['amount'], errors)}
                        onChange={({ target }: any) => setFieldValue('amount', target.value)}
                      />
                    )}
                  />
                </FormField>
                <FormField>
                  <FastField
                    name="transferReason"
                    render={({ field }: any) => (
                      <RadioGroup
                        {...field}
                        name="transferReason.type"
                        stackRadios={true}
                        label="Reason"
                        onChange={e => setFieldValue('transferReason.type', e.target.value)}
                        error={path(['transferReason', 'type'], errors)}
                      >
                        <Radio
                          name="unsettledInvoice"
                          value={transferReasons.unsettledInvoice}
                          label="Unsettled Invoice"
                        />
                        <Radio name="damageClaim" value={transferReasons.damageClaim} label="Damage Claim" />
                        <Radio name="leaseEnding" value={transferReasons.leaseEnding} label="Lease Ending" />
                        <Radio name="other" value={transferReasons.other} label="Other" />
                      </RadioGroup>
                    )}
                  />
                </FormField>
                <FormField
                  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; style: { display: strin... Remove this comment to see the full error message
                  style={{
                    display:
                      // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
                      values.transferReason === transferReasons.other ||
                      path(['type'], values.transferReason) === transferReasons.other
                        ? 'block'
                        : 'none',
                    paddingTop: '0',
                  }}
                >
                  <FastField
                    name="transferReason.reason"
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        inputComponent={
                          <TextInput
                            type={TextFieldTypes.text}
                            onChange={handleOtherReason}
                            placeholder="Other reason..."
                          />
                        }
                        error={path(['transferReason', 'reason'], errors)}
                      />
                    )}
                  />
                </FormField>
                <FormField>
                  <Checkbox
                    name="isConfirmed"
                    label={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `I confirm that I'm about to transfer
                        <strong>${formattedAmount}</strong> from <strong>${contactName as string}</strong>
                        deposit wallet for <strong>${
                          propertyAddress as string
                        }</strong> to their main transactional wallet.`,
                        }}
                      />
                    }
                    checked={values.isConfirmed}
                    onChange={(e: any) => setFieldValue('isConfirmed', e.checked)}
                    onBlur={handleBlur}
                    error={submitCount > 0 && path(['isConfirmed'], errors)}
                  />
                </FormField>
              </FormLoader>
            </Modal.Body>
          </Modal>
        )
      }}
    </Formik>
  )
}

DepositTransferModal.propTypes = propTypes

export default DepositTransferModal
