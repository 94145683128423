import React, { useEffect, useState } from 'react'
import { subscribe } from 'react-contextual'
import { useSelector } from 'react-redux'
import { all } from 'ramda'
import pluralize from 'pluralize'
import { PaymentsProvider, SearchFilterProvider } from '../../../providers'
import { BulkActions, Loader, TextField, TextInput } from '../..'
import { useTable } from '../../organisms/Table'
import { walletApiSelectors } from '../../../../modules/api/wallet'
import { userApiSelectors } from '../../../../modules/api/user'
import Dimmer from '../../atoms/Dimmer/Dimmer'
import { TextFieldTypes } from 'components/atoms/TextField/text-field.types'
import { $TSFixMe } from 'types/ts-migrate'
import { agencyApiSelectors } from 'modules/api/agency'

const PaymentsBulkActions = ({
  // provider props
  demandPayments,

  reversePayments,
  sendPop,
  isPopLoading,
  isReadOnly,
}: $TSFixMe) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'selection' does not exist on type '{}'.
  const { selection = [], setSelection } = useTable()

  const getPaymentById = useSelector(walletApiSelectors.getPaymentById)

  const userEmail = useSelector(userApiSelectors.getUserEmail)
  const agencyName = useSelector(agencyApiSelectors.getCurrentAgencyName)
  const selectedPayments = selection.map((s: $TSFixMe) => getPaymentById(s))
  const areAllSelectedPaymentsQueued = all((payment: $TSFixMe) => payment.paymentStatus === 'Queue', selectedPayments)
  const canSelectionRequestPop = all((payment: $TSFixMe) => payment.popAvailable, selectedPayments)

  const subjectText = `${agencyName}`
  const [sendToEmail, setSendToEmail] = useState(userEmail)
  const [subject, setSubject] = useState(subjectText)

  // Reset selection once pop sent
  useEffect(() => {
    if (!isPopLoading) {
      setSelection([])
      setSendToEmail(userEmail)
      setSubject(subjectText)
    }
  }, [isPopLoading, setSelection, setSendToEmail, setSubject, subjectText, userEmail])

  const actions = [
    {
      text: 'Skip Queue',
      count: selection.length,
      isVisible: areAllSelectedPaymentsQueued,
      confirmation: {
        title: 'Submit Payments',
        content: <p>You are about to submit {selection.length} approved payments for processing.</p>,
        onConfirm: () => {
          demandPayments(selection)
          setSelection([])
        },
      },
    },
    {
      text: 'Reverse',
      count: selection.length,
      isVisible: areAllSelectedPaymentsQueued,
      danger: true,
      confirmation: {
        title: 'Reverse Payments',
        content: (
          <p>
            You are about to reverse {selection.length} approved payments. The funds will be returned to the wallets.
          </p>
        ),
        onConfirm: () => {
          reversePayments(selection)
          setSelection([])
        },
      },
    },
    {
      text: 'Send POP',
      count: selection.length,
      isVisible: canSelectionRequestPop,
      confirmation: {
        title: 'Send Proof of Payment',
        content: (
          <div style={{ marginBottom: '32px' }}>
            {isPopLoading ? (
              <Loader />
            ) : (
              <>
                <p>
                  You have requested proof of payment for {selection.length} {pluralize('payment', selection.length)}.
                  Please enter email address below.
                </p>
                <TextField
                  inputComponent={
                    <TextInput
                      type={TextFieldTypes.text}
                      name="subject"
                      placeholder="EG: Proof of Payment"
                      onChange={(e: any) => setSubject(e.target.value)}
                      value={subject}
                    />
                  }
                  label="Subject"
                  dark={true}
                />
                <TextField
                  inputComponent={
                    <TextInput
                      type={TextFieldTypes.email}
                      name="sendToEmail"
                      placeholder="EG: john@doe.com"
                      onChange={(e: any) => setSendToEmail(e.target.value)}
                      value={sendToEmail}
                    />
                  }
                  label="Email"
                  dark={true}
                />
              </>
            )}
          </div>
        ),
        onConfirm: () => {
          sendPop({
            paymentIds: selection,
            sendToEmail,
            subject,
          })
        },
      },
    },
  ].filter(action => action.isVisible)

  return (
    <Dimmer active={isReadOnly}>
      <BulkActions isOpen={selection.length > 0 && actions.length > 0} actions={actions} />
    </Dimmer>
  )
}

export default subscribe(
  [PaymentsProvider, SearchFilterProvider],
  ({ demandPayments, reversePayments, sendPop, isPopLoading, isReadOnly }: any) => ({
    demandPayments,
    reversePayments,
    sendPop,
    isPopLoading,
    isReadOnly,
  }),
)(PaymentsBulkActions)
