import React, { useEffect, useMemo, useState } from 'react'
import { subscribe } from 'react-contextual'
import { SearchFilterProvider } from '../../../../views/providers'
import CommunicationsProvider from '../../CommunicationsProvider'
import styles from './EmailLogTable.module.scss'
import {
  AggregateTable,
  Button,
  ControlledTooltip,
  DataFilters,
  DateRangePicker,
  Header,
  Label,
  NoContent,
} from '../../../../views/components'
import EmailIcon from '../../../../views/components/atoms/Icons/Doc/EmailFill'
import NameCell from './NameCell'
import { map, path, pathOr, pipe, reverse, sortBy, uniq } from 'ramda'
import { stringUtils } from '../../../../utils'
import ResendEmailModal from './ResendEmailModal/ResendEmailModal'
import { format, isValid, lastDayOfMonth } from 'date-fns'
import { filterData } from 'components/organisms/DataFilters/dataFiltersUtils'
import { splitCamelCase } from 'utils/string'
import { $TSFixMe } from 'types/ts-migrate'

const EmailLogTable = ({
  filters,
  fetchEmails,
  resendEmail,
  searchQuery,
  activeFilterIndex,
  emailPeriod,
  isReadOnly,
}: any): React.ReactElement => {
  const [data, setData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedEmail, setSelectedEmail] = useState({})
  const [filterStartDate, setFilterStartDate] = useState(
    emailPeriod?.from ? new Date(emailPeriod.from) : new Date().setDate(1),
  )
  const [filterEndDate, setFilterEndDate] = useState(
    emailPeriod?.to ? new Date(emailPeriod.to) : lastDayOfMonth(new Date()),
  )

  const filter: $TSFixMe = pathOr({}, [activeFilterIndex], filters)

  const [tableFilters, setTableFilters] = useState<$TSFixMe>([])

  const handleTableFilterChange = (filters: $TSFixMe) => {
    setTableFilters(filters)
  }

  useEffect(() => {
    const newData = filter.emails.map((row: $TSFixMe) => ({
      ...row,
      // Fields for sorting and filtering
      meta: {
        category: row.templateCategory,
        type: splitCamelCase(row.emailKind),
        status: row.emailStatus,
        timestamp: row.createdAt,
      },
    }))

    setData(newData)

    setTableFilters([
      {
        label: 'Category',
        value: 'category',
        options: uniq(
          newData.map((row: $TSFixMe) => ({
            label: row.meta.category,
            isActive: false,
          })),
        ),
      },
      {
        label: 'Type',
        value: 'type',
        options: uniq(
          newData.map((row: $TSFixMe) => ({
            label: row.meta.type,
            isActive: false,
          })),
        ),
      },
      {
        label: 'Status',
        value: 'status',
        options: uniq(
          newData.map((row: $TSFixMe) => ({
            label: row.meta.status,
            isActive: false,
          })),
        ),
      },
    ])
  }, [filter.emails])

  const filteredEmails = useMemo(() => {
    return data.filter(p => stringUtils.jsonStringSearch(searchQuery, p))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery])

  const columns = [
    {
      Header: '',
      accessor: 'resend',
      style: {
        maxWidth: '80px',
      },
    },
    {
      Header: 'Type',
      accessor: 'type',
      sort: {
        enabled: true,
        order: false,
      },
    },
    {
      Header: 'Recipient',
      accessor: 'recipient',
      style: {
        minWidth: '300px',
      },
    },
    {
      Header: 'Sent',
      accessor: 'timestamp',
      sort: {
        enabled: true,
        order: false,
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      sort: {
        enabled: true,
        order: false,
      },
    },
  ]

  const handleResendClick = ({ messageId, emailAddress, emailKind }: any): void => {
    setIsModalOpen(true)
    setSelectedEmail({ messageId, email: emailAddress, type: emailKind })
  }

  const handleSubmit = (messageId: any, email: any): void => {
    setIsModalOpen(false)
    resendEmail(messageId, email)
  }

  const getLabelColour = (status: any): string => {
    switch (status) {
      case 'Sending':
        return '#403f3f'
      case 'Processed':
        return '#056dba'
      case 'Delivered':
      case 'Opened':
        return '#128269'
      case 'Failed':
      case 'Bounced':
        return '#eb0018'
      default:
        return '#403f3f'
    }
  }

  const emailsData = useMemo(() => {
    const buildEmailObj = (p: any): $TSFixMe => {
      return {
        id: p.messageId,
        meta: p.meta,
        recipient: (
          <NameCell
            name={p.emailAddress}
            // meta={p.email}
          />
        ),
        type: splitCamelCase(p.emailKind),
        status: (
          <Label className={styles['status-label']} style={{ backgroundColor: getLabelColour(p.emailStatus) }}>
            {p.emailStatus}
          </Label>
        ),
        timestamp: (
          <span className={styles['timestamp']}>
            <small>{format(new Date(p.createdAt), 'yyyy-MM-dd')} </small>
            <small>{format(new Date(p.createdAt), 'HH:mm:ss')}</small>
          </span>
        ),
        resend: (
          // @ts-expect-error
          <ControlledTooltip body="Resend email" place="above">
            <Button ghost className={styles.resend} disabled={isReadOnly} onClick={() => handleResendClick(p)} />
          </ControlledTooltip>
        ),
      }
    }

    const tableData = searchQuery
      ? pipe(map(buildEmailObj), filterData(tableFilters))(filteredEmails)
      : pipe(map(buildEmailObj), filterData(tableFilters))(data)

    // @ts-expect-error FIXME
    const sortedData = reverse(sortBy(path(['meta', 'timestamp']), tableData))

    return sortedData
  }, [data, filteredEmails, searchQuery, tableFilters, isReadOnly])

  return useMemo(
    () => (
      <div className={styles.root}>
        <div className={styles['header-wrapper']}>
          <Header tag="h1" icon={<EmailIcon />} text="Email Log" />
          <span className={styles['datepicker-wrapper']}>
            <DateRangePicker
              // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
              startDate={filterStartDate}
              endDate={filterEndDate}
              onChange={(date: any, isStartDate: any) => {
                if (isStartDate) {
                  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                  setFilterStartDate(format(date, 'yyyy-MM-dd'))
                  isValid(filterEndDate) && fetchEmails(format(date, 'yyyy-MM-dd'), format(filterEndDate, 'yyyy-MM-dd'))
                } else {
                  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                  setFilterEndDate(format(date, 'yyyy-MM-dd'))
                  isValid(filterStartDate) &&
                    fetchEmails(format(filterStartDate, 'yyyy-MM-dd'), format(date, 'yyyy-MM-dd'))
                }
              }}
              inline={false}
            />
          </span>
        </div>
        <DataFilters filters={tableFilters} onFiltersChanged={handleTableFilterChange} />
        {emailsData.length > 0 ? (
          <AggregateTable
            rowHeight={80}
            columns={columns}
            data={emailsData}
            enableSelection={false}
            interactive={false}
            index={activeFilterIndex}
          />
        ) : (
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'string'.
          <NoContent heading={<p>No emails for selected filters.</p>} />
        )}
        <ResendEmailModal
          isModalOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          selectedEmail={selectedEmail}
          onSubmit={handleSubmit}
        />
      </div>
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ),
    [
      columns,
      emailsData,
      isModalOpen,
      fetchEmails,
      filterEndDate,
      filterStartDate,
      selectedEmail,
      activeFilterIndex,
      tableFilters,
    ],
  )
}

export default subscribe(
  [CommunicationsProvider, SearchFilterProvider],
  ({ emailFilters, fetchEmails, emailPeriod, resendEmail, isReadOnly }, { searchQuery, activeFilterIndex }: any) => ({
    filters: emailFilters,
    fetchEmails,
    searchQuery,
    activeFilterIndex,
    emailPeriod,
    resendEmail,
    isReadOnly,
  }),
)(EmailLogTable)
