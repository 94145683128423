import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormField, Modal, TextField, TextInput } from '../../../../../views/components'
import { Formik } from 'formik'
import ConfirmationAllocationModal from '../ConfirmationAllocationModal/ConfirmationAllocationModal'
// import { useDispatch } from 'react-redux'
// import { walletApiEvents } from '../../../api/wallet'
import * as yup from 'yup'
import { subscribe } from 'react-contextual'
import BadReferencesProvider from '../../../BadReferencesProvider'
import { TextFieldTypes } from 'components/atoms/TextField/text-field.types'

const validationSchema = yup.object().shape({
  correctReference: yup.string().required('Required!'),
  clickupRef: yup.string().required('Required!'),
})
const AllocationModal = ({ isOpen, onClose, currentReference, closeReference }: any): null | React.ReactElement => {
  const [isSubmitted, setIsSubmitted] = useState(false)

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  return !currentReference ? null : (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        correctReference: '',
        clickupRef: '',
        notes: '',
      }}
      onSubmit={() => {
        setIsSubmitted(true)
      }}
    >
      {({ values, handleBlur, handleChange, submitForm, handleSubmit, errors, resetForm }) => {
        const handleClose = (): void => {
          onClose()
          resetForm()
        }
        const handleConfirm = (): void => {
          setIsSubmitted(false)
          onClose()
          closeReference()
        }

        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        const headerTitle = `Allocation for ref: ${currentReference.referenceUsed}`

        return (
          <>
            <Modal isOpen={!isSubmitted && isOpen} padding="sm" width="lg" showClose={false}>
              <Modal.Header title={headerTitle} />
              <Modal.Body
                actions={[
                  { label: 'Allocate', onClick: submitForm },
                  { label: 'Cancel', onClick: handleClose },
                ]}
              >
                <form onSubmit={handleSubmit}>
                  <FormField>
                    <TextField
                      inputComponent={
                        <TextInput
                          type={TextFieldTypes.text}
                          name="correctReference"
                          value={values.correctReference}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      }
                      label="Correct reference"
                      error={errors?.correctReference}
                    />
                  </FormField>
                  <FormField>
                    <TextField
                      inputComponent={
                        <TextInput
                          type={TextFieldTypes.url}
                          name="clickupRef"
                          value={values.clickupRef}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      }
                      label="Clickup reference"
                      error={errors?.clickupRef}
                    />
                  </FormField>
                  <FormField>
                    <TextField
                      inputComponent={
                        <TextInput
                          type={TextFieldTypes.text}
                          name="notes"
                          value={values.notes}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      }
                      label="Notes"
                      textarea={true}
                    />
                  </FormField>
                </form>
              </Modal.Body>
            </Modal>
            {isSubmitted && (
              <ConfirmationAllocationModal
                isOpen={isSubmitted}
                onClose={() => setIsSubmitted(false)}
                onConfirm={handleConfirm}
                currentReference={currentReference}
                allocatedToRef={values.correctReference}
                clickupRef={values.clickupRef}
                notes={values.notes}
              />
            )}
          </>
        )
      }}
    </Formik>
  )
}
AllocationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  currentReference: PropTypes.shape({
    txId: PropTypes.string,
    referenceUsed: PropTypes.string,
    amount: PropTypes.number,
    dateReceived: PropTypes.string,
  }),
}

export default subscribe([BadReferencesProvider], ({ closeReference }: any) => ({
  closeReference,
}))(AllocationModal)
