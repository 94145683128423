import React, { useState, useEffect, useMemo } from 'react'
import { format, differenceInSeconds } from 'date-fns'
import { get, map } from 'lodash-es'
import { subscribe } from 'react-contextual'
import { stringUtils } from '../../../../../utils'
import { InvoicesProvider, PaymentsProvider, SearchFilterProvider } from '../../../../providers'
import { AggregateTable, Header, CurrencyText, Label, Tooltip, Timer, DateRangePicker, NoContent } from '../../..'
import InvoiceIcon from '../../../atoms/Svgs/Invoice'
import PaymentsAltIcon from '../../../atoms/Svgs/PaymentsAlt'
import WithdrawIcon from '../../../atoms/Svgs/Withdraw'
import styles from './PaymentsTable.module.scss'
import { pathOr, splitAt } from 'ramda'
import { $TSFixMe } from 'types/ts-migrate'

const PaymentsTable = ({
  paymentFilters,
  paymentPeriod,
  searchQuery,
  activeFilterIndex,
  getPayments,
}: any): React.ReactElement => {
  const [data, setData] = useState([])
  const [startDate, setStartDate] = useState(paymentPeriod.from ? new Date(paymentPeriod.from) : new Date())
  const [endDate, setEndDate] = useState(paymentPeriod.to ? new Date(paymentPeriod.to) : new Date())
  const [mouseOverItem, setMouseOverItem] = useState(null)
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const filterByType = get(paymentFilters, `[${[activeFilterIndex]}]`, {})

  useEffect(() => {
    setData(filterByType.payments)
  }, [activeFilterIndex, filterByType, startDate, endDate])

  const filteredPayments = useMemo(() => {
    return data.filter(inv => stringUtils.jsonStringSearch(searchQuery, inv))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery])

  const getButtonColour = (status: any): string => {
    switch (status) {
      case 'Queue':
        return '#056dba'
      case 'Verified':
        return '#128269'
      case 'Requested':
      case 'Demanded':
      case 'AwaitingTransfer':
        return '#403f3f'
      case 'Reversed':
      case 'WalletRejected':
      case 'BankRejected':
      case 'RequestRejected':
        return '#eb0018'
      default:
        return '#056dba'
    }
  }

  const getButtonLabelAlias = (status: any): string => {
    switch (status) {
      case 'Demanded':
        return 'Submitted'
      default:
        return status
    }
  }

  const getSecondsToSubmission = (payoutAt: any): number => {
    const countdownTime = differenceInSeconds(payoutAt, new Date())
    return countdownTime > 0 ? countdownTime : 0
  }

  const columns = [
    {
      Header: 'Payment Type',
      accessor: 'paymentType',
      style: {
        maxWidth: '300px',
        display: 'flex',
        flexDirection: 'row',
      },
    },
    {
      Header: 'To',
      accessor: 'to',
      style: {
        maxWidth: '300px',
        display: 'flex',
        flexDirection: 'row',
      },
    },
    {
      Header: 'From',
      accessor: 'from',
      style: {
        justifyContent: 'center',
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    {
      Header: 'Approved',
      accessor: 'approvedAt',
      style: {
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '125px',
        alignItems: 'flex-start',
      },
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      style: {
        display: 'flex',
        justifyContent: 'flex-end',
        maxWidth: '150px',
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      style: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: '20px',
        maxWidth: '150px',
      },
    },
  ]

  const paymentKindRenderer = (paymentKind: string) => {
    switch (paymentKind) {
      case 'EFTPartyPayment':
        return 'Outgoing EFT'
      case 'DepositRefund':
        return 'Deposit Refund'
      case 'WalletTransfer':
        return 'Wallet Transfer'
      case 'DepositPayment':
        return 'Deposit Transfer In'
      case 'DepositTransfer':
        return 'Deposit Transfer Out'
      case 'EasypayPayment':
        return 'EasyPay Payment'
      default:
        return paymentKind.replace(/([a-z0-9])([A-Z])/g, '$1 $2')
    }
  }

  const getTransactionId = (id: any): string => pathOr('', [0], splitAt(6, id))

  const paymentsData = useMemo(() => {
    const buildPaymentsObj = (payment: any): $TSFixMe => ({
      id: payment.id,
      enableSelection: payment.enableSelection,

      paymentType: (
        <>
          {payment.paymentKind.type === 'WalletTransfer' ? (
            <PaymentsAltIcon title="Wallet Transfer" />
          ) : (
            <WithdrawIcon title="EFT" />
          )}
          <span className={styles['transaction-id-wrapper']}>
            <strong>{paymentKindRenderer(payment.paymentKind.type)}</strong>
            <small>Transaction ID: {getTransactionId(payment.id)}</small>
          </span>
        </>
      ),

      to: (
        <>
          <span>
            <strong>{payment.paymentKind.partyFullname}</strong>
            {payment.paymentKind.bankName &&
            payment.paymentKind.bankAccountLastFour &&
            (payment.paymentKind.type === 'EFTPartyPayment' || payment.paymentKind.type === 'DepositRefund') ? (
              <small className={styles['bank-details']}>
                {payment.paymentKind.bankName}
                &#x2a;&#x2a;&#x2a;&#x2a;&#x2a;
                {payment.paymentKind.bankAccountLastFour}
              </small>
            ) : null}
            {payment.paymentKind.easypayReference ? (
              <small className={styles['reference']}>Reference: {payment.paymentKind.easypayReference}</small>
            ) : payment.paymentKind.bankReference &&
              (payment.paymentKind.type === 'EFTPartyPayment' ||
                payment.paymentKind.type === 'EasypayPayment' ||
                payment.paymentKind.type === 'DepositRefund') ? (
              <small className={styles['reference']}>Reference: {payment.paymentKind.bankReference}</small>
            ) : null}
          </span>
        </>
      ),

      from: (
        <>
          <span>
            <strong>{payment.fromFullname}</strong>
            <div
              style={{
                textOverflow: 'ellipsis',
                width: '100%',
                overflow: 'hidden',
                fontSize: 'smaller',
              }}
            >
              {payment.fromProperty}
            </div>
          </span>
        </>
      ),

      approvedAt: (
        <div
          style={{
            textOverflow: 'ellipsis',
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <small>
            {format(payment.approvedAt, 'yyyy-MM-dd')}
            <br />
            {format(payment.approvedAt, 'HH:mm:ss')}
            <br />
            {payment.approvedByFullname}
          </small>
        </div>
      ),

      amount: (
        <div style={{ textAlign: 'right' }}>
          <CurrencyText>{payment.amount}</CurrencyText>
        </div>
      ),

      status: (
        <Tooltip
          place="left"
          body={
            <span>
              Payment will be submitted in <Timer duration={getSecondsToSubmission(payment.payoutAt)} />
            </span>
          }
          isOpen={payment.paymentStatus === 'Queue' && mouseOverItem === payment.id}
        >
          <Label
            className={styles['status-label']}
            style={{ backgroundColor: getButtonColour(payment.paymentStatus) }}
            onMouseOver={() => setMouseOverItem(payment.id)}
            onMouseLeave={() => setMouseOverItem(null)}
          >
            {getButtonLabelAlias(payment.paymentStatus)}
          </Label>
        </Tooltip>
      ),
    })

    return searchQuery ? map(filteredPayments, buildPaymentsObj) : map(data, buildPaymentsObj)
  }, [data, filteredPayments, searchQuery, mouseOverItem])

  return useMemo(
    () => (
      <div className={styles.root}>
        <div className={styles['header-wrapper']}>
          <Header icon={<InvoiceIcon />} text="Payments" />
          <span className={styles['datepicker-wrapper']}>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onChange={(date: any, isStartDate: any) => {
                if (date) {
                  if (isStartDate) {
                    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    setStartDate(format(date, 'yyyy-MM-dd'))
                    getPayments(format(date, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd'))
                  } else {
                    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    setEndDate(format(date, 'yyyy-MM-dd'))
                    getPayments(format(startDate, 'yyyy-MM-dd'), format(date, 'yyyy-MM-dd'))
                  }
                }
              }}
              inline={false}
            />
          </span>
        </div>

        {paymentsData.length > 0 ? (
          <AggregateTable
            enableSelection
            hideHeaderSelection
            columns={columns}
            data={paymentsData}
            rowHeight={80}
            interactive={false}
          />
        ) : (
          <NoContent heading="There are no payments yet." />
        )}
      </div>
    ),
    [columns, paymentsData, startDate, endDate, getPayments],
  )
}

export default subscribe(
  [PaymentsProvider, SearchFilterProvider],
  ({ paymentFilters, paymentPeriod, getPayments }: any, { searchQuery, activeFilterIndex }: any) => ({
    paymentFilters,
    paymentPeriod,
    searchQuery,
    activeFilterIndex,
    getPayments,
  }),
)(PaymentsTable)
