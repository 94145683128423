import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { subscribe } from 'react-contextual'
import cx from 'classnames'
import { map } from 'ramda'
import { agencyApiEvents } from 'modules/api/agency'
import { SearchFilterProvider, SettingsProvider } from '../../../providers'
import { AggregateTable, ControlledTooltip, Header, InitialsBadge, ListingTemplate, SearchFilter, Select } from '../..'
import { ConfirmUserRoleChange, RevokeUserConfirmationModal, StatefulOtpVerification } from '../../../../modules/user'
import { TableProvider } from '../../organisms/Table'
import { Helmet } from 'react-helmet'
import { dateUtils, stringUtils } from '../../../../utils'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../../../../theme/icons-v2/Use... Remove this comment to see the full error message
import AddTeamMemberIcon from '../../../../theme/icons-v2/User/Contact-add-fill.svg'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../../../../theme/icons-v2/Use... Remove this comment to see the full error message
import EditTeamMemberIcon from '../../../../theme/icons-v2/User/Contact-settings-fill.svg'
import styles from './UsersPage.module.scss'
import { splitCamelCase } from 'utils/string'
import { $TSFixMe } from 'types/ts-migrate'
import { userApiSelectors } from 'modules/api/user'

const UsersPage = ({
  teamMembers,
  agentInvitations,
  agentMembers,
  location,
  redirect,
  requestRevokeAccess,
  requestRevokeInvite,
  requestSendPartyInvite,
  resendInvite,
  searchQuery,
  activeFilterIndex,
}: any): React.ReactElement => {
  const dispatch = useDispatch()
  const filterNames = ['Users', 'Invites', 'Agents']

  const [activeFilterName, setActiveFilterName] = useState('')
  const [otpOpen, setOtpOpen] = useState(false)
  const [isRevokeUserConfirmationModalOpen, setIsRevokeUserConfirmationModalOpen] = useState(false)
  const [confirmUserRoleChange, setConfirmUserRoleChange] = useState<{
    isOpen: boolean
    userId: string | null
    role: string | null
  }>({
    isOpen: false,
    userId: null,
    role: null,
  })
  const [selectedUserId, setSelectedUserId] = useState(null)
  const [selectedPartyId, setSelectedPartyId] = useState(null)
  const [invitePayload, setInvitePayload] = useState<$TSFixMe>(null)
  const [roles, setRoles] = useState<$TSFixMe>({})
  const isCurrentUserOwnerRole = useSelector(userApiSelectors.isOwnerRole)

  useEffect(() => {
    setActiveFilterName(filterNames[activeFilterIndex])
  }, [filterNames, activeFilterIndex])

  const getListItems = useCallback(() => {
    switch (activeFilterName) {
      case 'Users':
        return teamMembers
      case 'Invites':
        return agentInvitations
      case 'Agents':
        return agentMembers
      default:
        return []
    }
  }, [activeFilterName, teamMembers, agentInvitations, agentMembers])

  const filteredListItems = useMemo(() => {
    return getListItems().filter((item: any) => stringUtils.jsonStringSearch(searchQuery, item))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery])

  const sendOtpPartyInvite = (payload: $TSFixMe): void => {
    setOtpOpen(true)
    setInvitePayload(payload)
  }

  const resendOtpPartyInvite = (partyId: any): void => {
    setOtpOpen(true)
    setSelectedPartyId(partyId)
  }

  const handleOtpSubmit = ({ otp }: any): void => {
    const { pin } = otp
    setOtpOpen(false)
    activeFilterName === 'Invites'
      ? requestSendPartyInvite(selectedPartyId, pin)
      : dispatch(
          agencyApiEvents.sendPartyInvite_request({
            params: { partyId: invitePayload.id },
            body: { otp: pin, role: invitePayload.role },
          }),
        )
  }

  const columns = [
    {
      Header: '',
      accessor: 'badge',
      style: {
        paddingLeft: '16px',
        minWidth: '60px',
        maxWidth: '80px',
      },
    },
    {
      Header: 'Name',
      accessor: 'name',
      style: {
        maxWidth: '300px',
        display: 'flex',
        flexDirection: 'row',
      },
    },
    {
      Header: 'Contact Number',
      accessor: 'contactNumber',
      style: {
        justifyContent: 'center',
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        maxWidth: '200px',
      },
    },
    {
      Header: 'Email',
      accessor: 'email',
      style: {
        justifyContent: 'center',
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        maxWidth: '500px',
      },
    },
    {
      Header: 'User Role',
      accessor: 'userRole',
      style: {
        justifyContent: 'center',
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        overflow: 'visible',
        maxWidth: '200px',
      },
    },
    {
      Header: '',
      accessor: 'action',
      style: {
        display: 'flex',
        justifyContent: 'flex-end',
        maxWidth: '70px',
      },
    },
  ]

  const userData = useMemo(() => {
    const buildNameTag = (combinedName: any, person: any): React.ReactElement => {
      const { lastLoggedIn, status } = person
      let subText = ''

      switch (activeFilterName) {
        case 'Users': {
          subText = 'Logged in ' + dateUtils.timeAgo(new Date(lastLoggedIn))
          break
        }
        case 'Invites': {
          const prefix = status === 'Pending' ? 'Invite sent ' : status === 'Expired' ? 'Expired ' : 'Revoked '
          const dateType = status === 'Pending' ? 'createdAt' : status === 'Expired' ? 'expirationDate' : 'revokedAt'
          if (person[dateType] !== undefined) {
            subText = prefix + dateUtils.timeAgo(new Date(person[dateType]))
          }
          break
        }
        case 'Agents': {
          subText = '' // 'Agent on x leases'
          break
        }
        default: {
          subText = '-'
          break
        }
      }

      return (
        <div>
          <strong>{combinedName}</strong> <br />
          {subText}
        </div>
      )
    }

    const buildTooltipButton = (person: any): React.ReactElement | null => {
      const { userId, partyId, status } = person
      let text = ''
      let icon: string = ''
      let action = (): void => {}

      switch (activeFilterName) {
        case 'Users': {
          text = 'Revoke Access'
          icon = styles['send-invite-revoke']
          action = () => {
            setSelectedUserId(userId)
            setIsRevokeUserConfirmationModalOpen(true)
          }
          break
        }
        case 'Invites': {
          text = status === 'Pending' ? 'Revoke Invite' : status === 'Expired' ? 'Resend Invite' : 'Send Invite'
          icon = status === 'Pending' ? styles['send-invite-revoke'] : styles['send-invite-send']
          action =
            status === 'Pending'
              ? () => requestRevokeInvite(partyId)
              : status === 'Expired'
              ? () => resendInvite(partyId)
              : () => resendOtpPartyInvite(partyId)
          break
        }
        case 'Agents': {
          text = 'Send Invite'
          icon = styles['send-invite-send']
          action = () => sendOtpPartyInvite({ id: userId, role: roles[userId] })
          break
        }
        default:
      }

      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!text || !icon || !action) {
        return null
      }

      return (
        // @ts-expect-error
        <ControlledTooltip body={text} place="left">
          <button className={cx([styles.action, icon])} onClick={() => action()} />
        </ControlledTooltip>
      )
    }

    const buildListObj = (person: $TSFixMe): $TSFixMe => {
      const { userId, firstName, lastName, cellNumber, emailAddress, status } = person
      const combinedName = `${firstName as string} ${lastName as string}`
      const agentRole = roles[userId] || 'TeamMember'

      const options = [
        {
          value: 'Owner',
          label: 'Account Owner',
        },
        {
          value: 'TeamMember',
          label: 'Team Member',
        },
        {
          value: 'ReadOnlyMember',
          label: 'Read Only',
        },
      ]

      return {
        id: userId,
        badge: (
          <InitialsBadge
            name={combinedName}
            colour={activeFilterName === 'Invites' ? (status === 'Pending' ? 'green' : 'red') : 'none'}
          />
        ),
        enableSelection: false,
        name: buildNameTag(combinedName, person),
        contactNumber: cellNumber,
        email: emailAddress,
        userRole:
          activeFilterName === 'Users' && isCurrentUserOwnerRole && person.role !== 'Owner' ? (
            <Select
              name="userRole"
              value={options.find((option: $TSFixMe) => option.value === person.role)}
              onChange={(option: $TSFixMe) =>
                setConfirmUserRoleChange({ ...confirmUserRoleChange, isOpen: true, userId, role: option.value })
              }
              options={options}
            />
          ) : activeFilterName === 'Agents' ? (
            <Select
              name="userRole"
              value={options.find((option: $TSFixMe) => option.value === agentRole)}
              onChange={(option: $TSFixMe) => setRoles({ ...roles, [userId]: option.value })}
              options={options}
            />
          ) : person.role === 'Owner' ? (
            'Account Owner'
          ) : (
            splitCamelCase(person.role)
          ),
        action: buildTooltipButton(person),
      }
    }

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    return searchQuery ? map(buildListObj, filteredListItems) : map(buildListObj, getListItems())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getListItems, filteredListItems, searchQuery, activeFilterName, roles])

  const searchFilterProps = {
    redirect,
    filters: [
      {
        key: 'users',
        label: 'Users',
        data: userData,
        count: teamMembers.length,
      },
      {
        key: 'invites',
        label: 'Invites',
        data: userData,
        count: agentInvitations.length,
      },
      {
        key: 'agents',
        label: 'Agents',
        data: userData,
        count: agentMembers.length,
      },
    ],
    pathname: location.pathname,
  }

  return useMemo(
    () => (
      <>
        <TableProvider>
          <ListingTemplate
            loading={false}
            Header={<SearchFilter {...searchFilterProps} />}
            Body={
              <div className={styles.root}>
                <Helmet>
                  <title>reOS | Settings | Users</title>
                </Helmet>
                <div className="header-wrapper">
                  <Header
                    icon={
                      activeFilterName === 'Users' ? (
                        <img src={EditTeamMemberIcon} alt="Edit Team Member" />
                      ) : (
                        <img src={AddTeamMemberIcon} alt="Add Team Member" />
                      )
                    }
                    text={activeFilterName}
                  />
                </div>
                <AggregateTable
                  enableSelection={false}
                  hideHeaderSelection
                  columns={columns}
                  data={userData}
                  rowHeight={80}
                  interactive={false}
                />
                <StatefulOtpVerification
                  isOpen={otpOpen}
                  onClose={() => setOtpOpen(false)}
                  onSubmit={handleOtpSubmit}
                />
                <RevokeUserConfirmationModal
                  isOpen={isRevokeUserConfirmationModalOpen}
                  onRevoke={() => {
                    requestRevokeAccess(selectedUserId)
                    setIsRevokeUserConfirmationModalOpen(false)
                  }}
                  onCancel={() => setIsRevokeUserConfirmationModalOpen(false)}
                />
                <ConfirmUserRoleChange
                  {...confirmUserRoleChange}
                  onCancel={() => setConfirmUserRoleChange({ userId: null, role: null, isOpen: false })}
                  onConfirm={() => setConfirmUserRoleChange({ userId: null, role: null, isOpen: false })}
                />
              </div>
            }
          />
        </TableProvider>
      </>
    ),
    [columns, userData, activeFilterName, searchFilterProps, handleOtpSubmit, otpOpen],
  )
}

export default subscribe(
  [SettingsProvider, SearchFilterProvider],
  (
    {
      teamMembers,
      agentInvitations,
      agentMembers,
      location,
      redirect,
      requestRevokeAccess,
      requestRevokeInvite,
      requestSendPartyInvite,
      resendInvite,
    }: any,
    { searchQuery, activeFilterIndex }: any,
  ) => ({
    teamMembers,
    agentInvitations,
    agentMembers,
    location,
    redirect,
    requestRevokeAccess,
    requestRevokeInvite,
    requestSendPartyInvite,
    resendInvite,
    searchQuery,
    activeFilterIndex,
  }),
)(UsersPage)
