import React from 'react'
import styles from './Grid.module.scss'

type TProps = {
  xs?: number
  sm?: number
  md?: number
  style?: object
  flexOptions?: string
  className?: string
  children: React.ReactNode
  split?: boolean
}

const Column = ({ xs, sm, md, style, flexOptions, className, children, split }: TProps) => {
  const classNames: string[] = []
  xs ? classNames.push(styles[`col-xs-${xs}`]) : classNames.push(`${styles['col-xs']}`)
  sm ? classNames.push(styles[`col-sm-${sm}`]) : classNames.push(`${styles['col-sm']}`)
  md ? classNames.push(styles[`col-md-${md}`]) : classNames.push(`${styles['col-md']}`)
  split && classNames.push(`${styles['col-split']}`)
  if (className) {
    classNames.push(className)
  }
  flexOptions?.split(' ').forEach((opt: any) => classNames.push(styles[opt]))

  return <div className={classNames.join(' ')}>{children}</div>
}

export default Column
