import React from 'react'
import Column from './Column'
import styles from './Grid.module.scss'

type TProps = {
  flexOptions?: string
  style?: object
  children: React.ReactNode
}

const Grid = ({ flexOptions, style, children }: TProps) => {
  const classNames = [styles.grid]
  flexOptions?.split(' ').forEach((opt: any) => classNames.push(styles[opt]))

  return (
    <div className={classNames.join(' ')} style={style}>
      {children}
    </div>
  )
}

Grid.Column = Column

export default Grid
