import React from 'react'
import Button from '../../atoms/Button/Button'

type TProps = {
  children: React.ReactNode
  actions?: {
    label: string
    onClick: () => void
  }[]
}

const ModalBody = ({ children, actions = [] }: TProps) => (
  <div className="Modal_Content">
    {children}
    {actions.length > 0 && (
      <div className="Modal_Actions">
        {actions.map(action => (
          <Button key={action.label} link onClick={action.onClick}>
            {action.label}
          </Button>
        ))}
      </div>
    )}
  </div>
)

export default ModalBody
