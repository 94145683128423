import React, { FC, useCallback, useEffect, useState } from 'react'
import { Button, DimmerLoader, Header, InfoBox, ListingTemplate, Modal, OtpVerification } from 'views/components'
import { subscribe } from 'react-contextual'
import Dropzone from 'react-dropzone'
import ImportIcon from 'views/components/atoms/Icons/Doc/Csv'
import styles from './BulkImportContacts.module.scss'
import AgencySelect, { TAgencyOption } from 'modules/supportStaff/components/AgencySelect/AgencySelect'
import { useEffectOnce } from 'react-use'
import { SupportStaffProvider } from 'modules/supportStaff'
import { useDispatch, useSelector } from 'react-redux'
import { userApiSelectors } from 'modules/api/user'
import { pathOr } from 'ramda'
import { fileUtils } from 'utils/index'
import { partyApiEvents, partyApiSelectors } from 'modules/api/party'
import { notificationEvents } from 'modules/notificationCenter'
import { apiStateSelectors } from 'modules/api/apiState'

type TState = {
  otp: string | null
  agency: TAgencyOption | null
  isOtpOpen: boolean
  csvBase64: string | null
  isConfirmationOpen: boolean
}

type TError = {
  row: number
  message: string
}

const parseErrors = (errors: TError[]) => errors.map(err => `Row ${err.row + 1}: ${err.message}`)

const BulkImportContactsBody: FC = subscribe(
  [SupportStaffProvider],
  ({ fetchAgencyListing, agencyListing, agencyLogin }) => ({
    fetchAgencyListing,
    agencyListing,
    agencyLogin,
  }),
)(({ fetchAgencyListing, agencyListing, agencyLogin }) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(state => apiStateSelectors.isLoading(state)([partyApiEvents.bulkImport_request]))
  const bulkImportResult = useSelector(partyApiSelectors.getBulkImportResult)
  const bulkImportErrors = pathOr([], ['errors'], bulkImportResult)
  const importedParties = pathOr([], ['parties'], bulkImportResult)
  const isDisabled = false

  const initialState = {
    agency: null,
    otp: null,
    isOtpOpen: false,
    csvBase64: null,
    isConfirmationOpen: false,
  }
  const [state, setState] = useState<TState>(initialState)

  const [agencyOptions, setAgencyOptions] = useState([])
  const currentAgencyId = useSelector(userApiSelectors.getCurrentAgencyId)
  const currentAgencyOption: TAgencyOption | undefined = agencyOptions.find(
    (a: TAgencyOption) => a.value === currentAgencyId,
  )

  useEffectOnce(() => {
    setState(initialState) // reset state on initial mount
    dispatch(partyApiEvents.clearBulkImportResult())
    fetchAgencyListing()
  })

  /**
   * Set initial agency state
   */
  useEffect(() => {
    if (currentAgencyOption) {
      setState(s => ({ ...s, agency: currentAgencyOption }))
    }
  }, [currentAgencyOption])

  /**
   * Set agency Select options
   */
  useEffect(() => {
    if (agencyListing) {
      setAgencyOptions(
        agencyListing.map((a: any) => ({
          // only used for searching
          label: `${a.agencyName}${a?.tradingAs && ` (${a.tradingAs})`}`,

          agencyName: a.agencyName,
          tradingAs: `Trading as: ${a?.tradingAs ? a.tradingAs : 'N/A'}`,
          value: a.agencyId,
        })),
      )
    }
  }, [agencyListing])

  /**
   * Handle OTP submission
   */
  const handleOtpSubmission = useCallback(
    ({ pin }) => {
      setState({ ...state, otp: pin })
    },
    [setState, state],
  )

  const handleClose = useCallback(() => {
    setState({ ...state, isOtpOpen: false })
  }, [setState])

  const handleAgencySelect = useCallback(
    (agency: TAgencyOption) => {
      setState({ ...state, agency, isOtpOpen: true })
    },
    [setState],
  )

  const handleFileDrop = async (files: any) => {
    try {
      const csvBase64 = await fileUtils.toBase64(files[0])
      setState({ ...state, csvBase64, isConfirmationOpen: true })
    } catch (error) {
      dispatch(
        notificationEvents.addNotification_request({
          message: 'There was a problem uploading your CSV',
          type: 'error',
        }),
      )
    }
  }

  /**
   * Handle agency login
   */
  useEffect(() => {
    if (state.agency !== null && state.otp !== null) {
      agencyLogin({ ...state.agency, agencyId: state.agency.value, otp: state.otp })
    }
  }, [state, agencyLogin])

  /**
   * Handle upload confirmation
   */
  const handleImport = useCallback(() => {
    const { agency, csvBase64 } = state
    if (agency !== null && csvBase64 !== null) {
      dispatch(partyApiEvents.bulkImport_request({ base64: state.csvBase64 as string }))
      setState({ ...state, isConfirmationOpen: false })
    }
  }, [state])

  /**
   * Success Notification
   */

  return (
    <div className={styles.root}>
      <Header icon={<ImportIcon />} text="Bulk Import Contacts" />

      <InfoBox className={styles.info}>
        <p>
          You are currently logged in to: <strong>{pathOr('', ['label'], currentAgencyOption)}</strong>
        </p>
      </InfoBox>

      {bulkImportErrors.length > 0 && (
        <InfoBox className={styles.info} type="error">
          <ul>
            {parseErrors(bulkImportErrors).map((err, i) => (
              <li key={i}>{err}</li>
            ))}
          </ul>
        </InfoBox>
      )}

      {importedParties.length > 0 && (
        <InfoBox className={styles.info} type="success">
          {importedParties.length} contacts have been imported successfully
        </InfoBox>
      )}

      <AgencySelect agencyOptions={agencyOptions} onAgencySelection={handleAgencySelect} value={currentAgencyOption} />

      <OtpVerification isOpen={state.isOtpOpen} onSubmit={handleOtpSubmission} onClose={handleClose} />

      <div className={styles.dropzone}>
        <DimmerLoader bgColor="transparent" loading={isLoading} disabled={isDisabled}>
          <Dropzone onDrop={handleFileDrop} multiple={false} style={null} className="drop-zone">
            <p>
              Drag and drop a CSV file here or <button>browse to choose a file</button>
            </p>
          </Dropzone>
        </DimmerLoader>
      </div>

      <Modal
        isOpen={state.isConfirmationOpen}
        width="sm"
        onClose={() => setState({ ...state, isConfirmationOpen: false })}
        theme="dark"
        className={styles['confirm-modal']}
      >
        <h3 className="Modal_Title">Confirm Import</h3>
        <div className="Modal_Content">Are you sure you want to import this CSV?</div>
        <div className="Modal_Actions">
          <Button link onClick={handleImport}>
            Yes, import
          </Button>
          <Button link onClick={() => setState({ ...state, isConfirmationOpen: false })}>
            No, cancel
          </Button>
        </div>
      </Modal>
    </div>
  )
})

const HeaderWrapper = () => <div className={styles.header} dangerouslySetInnerHTML={{ __html: '<p></p>' }} />

const BulkImportContactsRoute: FC = props => {
  return (
    <div>
      <SupportStaffProvider>
        <ListingTemplate Header={<HeaderWrapper />} Body={<BulkImportContactsBody />} />
      </SupportStaffProvider>
    </div>
  )
}

export default BulkImportContactsRoute
