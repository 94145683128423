import { createEpicMiddleware } from 'redux-observable'
import { routerMiddleware } from 'connected-react-router'
import { AnyAction } from 'redux'
import reduxHistoryPushMiddleware from './middleware/reduxHistoryPush'
import * as Sentry from '@sentry/react'
import { Dependencies } from 'StateTypes'
import sentryContextMiddleware from './middleware/sentryContext'
import tokenExpiryMiddleware from './middleware/tokenExpiry'
import intercomReactReduxRouter from './middleware/intercomReactReduxRouter'
import reactGAMiddleware from './middleware/reactGA'
import { createReducer, rootEpic } from './root'
import * as restful from '../utils/restful'
import history from './history'

import { uiModel } from '../modules/ui'
import { reconApiUtils } from '../modules/api/recon'
import { configureStore } from '@reduxjs/toolkit'
import { userApiEvents } from 'modules/api/user'
import { mergeDeepRight } from 'ramda'
import { $TSFixMe } from 'types/ts-migrate'

const windowLocation = () => window.location
type RootAction = $TSFixMe
export interface EpicDependencies extends Dependencies {
  windowLocation: typeof windowLocation
}
const epicMiddleware = createEpicMiddleware<RootAction, RootAction, $TSFixMe, EpicDependencies>({
  dependencies: { ...restful, windowLocation },
})

const reduxHistoryPush = reduxHistoryPushMiddleware()

// Build the middleware for intercepting and dispatching navigation actions
const router = routerMiddleware(history)

/**
 * Return null to not log the action to Sentry
 * Return a transformed action to remove sensitive information
 */
const sentryActionTransformer = (action: AnyAction) => {
  if (action.type === userApiEvents.login_request.toString()) {
    return mergeDeepRight(action, {
      payload: { password: null },
    })
  }
  if (
    [
      userApiEvents.login_success.toString(),
      userApiEvents.signup_success.toString(),
      userApiEvents.userResumed.toString(),
      userApiEvents.passwordReset_success.toString(),
    ].includes(action.type)
  ) {
    return mergeDeepRight(action, {
      payload: { authToken: null, refreshToken: null },
    })
  }
  if (action.type === userApiEvents.userAuthTokenDecoded.toString()) {
    return mergeDeepRight(action, {
      payload: {
        source: null,
        value: {
          totpSecret: null,
        },
      },
    })
  }
  if (action.type === userApiEvents.passwordReset_request.toString()) {
    return mergeDeepRight(action, {
      payload: { password: null, passwordConfirmation: null },
    })
  }

  return action
}

/**
 * Remove sensitive and large data
 * Not attaching state for the time being. I think action logs is sufficient
 */
const sentryStateTransformer = (state: RootState) => {
  return null
  // return mergeDeepRight(state, {
  //   api: {
  //     /**
  //      * reduce large data if necessary. Examples below
  //      */
  //     agency: {
  //       agencyListing: state.api.agency.agencyListing.map(({ agencyId, agencyName, isActive }) => ({
  //         agencyId,
  //         agencyName,
  //         isActive,
  //       })),
  //     },
  //     invoice: {
  //       invoices: state.api.invoice.invoices.map(({ id }) => id),
  //     },
  //     party: {
  //       entities: {
  //         parties: Object.keys(state.api.party.entities.parties),
  //       },
  //     },
  //     portfolio: {
  //       summaries: state.api.portfolio.summaries.map(({ portfolioId }) => portfolioId),
  //       byId: Object.keys(state.api.portfolio.summaries),
  //     },
  //     user: {
  //       authToken: null,
  //       refreshToken: null,
  //     },
  //   },
  // })
}

function configureAppStore(preloadedState) {
  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    actionTransformer: sentryActionTransformer,
    stateTransformer: sentryStateTransformer,
  })

  const store = configureStore({
    reducer: createReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat([
        epicMiddleware,
        router,
        reduxHistoryPush,
        reactGAMiddleware,
        intercomReactReduxRouter,
        sentryContextMiddleware,
        tokenExpiryMiddleware,
      ]),
    devTools: true,
    enhancers: [sentryReduxEnhancer],
    preloadedState,
  })

  epicMiddleware.run(rootEpic)

  return store
}

const nudgedInvoices = reconApiUtils.getNudgedInvoices()

const initialState = {
  ui: {
    ...uiModel,
    invoices: {
      ...uiModel.invoices,
      nudgedInvoices,
    },
  },
}

const store = configureAppStore(initialState)

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export default store
