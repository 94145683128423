import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { FastField, Formik } from 'formik'
import { isEmpty } from 'ramda'
import * as yup from 'yup'
import { FormField, FormLoader, Modal, TextField, TextInput } from '../../../../../views/components'
import { splitCamelCase } from 'utils/string'
import styles from './ResendEmailModal.module.scss'
import { TextFieldTypes } from 'views/components/atoms/TextField/text-field.types'
import { $TSFixMe } from 'types/ts-migrate'

const propTypes = {
  isModalOpen: PropTypes.bool,
  onClose: PropTypes.func,
  selectedEmail: PropTypes.shape({
    email: PropTypes.string,
    messageId: PropTypes.string,
    type: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
}

const validationSchema = yup.object().shape({
  emailAddress: yup.string().email('Email address format is invalid.'),
})

const ButtonContainer = ({ children }: any) => <div className="modal-action">{children}</div>

const ResendEmailModal = ({ isModalOpen, onClose, selectedEmail, onSubmit }: any) => {
  const { messageId, email, type } = selectedEmail

  const handleModalClose = (): void => {
    onClose()
  }

  const handleSubmit = useCallback(
    values => {
      onSubmit(messageId, [values.emailAddress])
    },
    [onSubmit, messageId],
  )

  return (
    <Modal isOpen={isModalOpen} onClose={() => handleModalClose()} width="md">
      <h3 className={styles['modal-header']}>Resend Email</h3>
      <div className={styles['modal-info']}>
        <span className={styles['modal-item-wrapper']}>
          <div className={styles['modal-label']}>Email type:</div>
          {splitCamelCase(type)}
        </span>
        <span className={styles['modal-item-wrapper']}>
          <div className={styles['modal-label']}>Original recipient:</div>
          {email}
        </span>
      </div>

      <div className="modal-content">
        <Formik
          initialValues={{ emailAddress: selectedEmail?.email }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, handleSubmit, isSubmitting, values, errors }) => {
            const formLoaderState = isSubmitting ? 'submitting' : !isEmpty(errors) ? 'error' : undefined

            return (
              <FormLoader
                onSubmit={handleSubmit}
                state={formLoaderState}
                buttonProps={{ children: 'Send' }}
                ButtonContainer={ButtonContainer}
              >
                <FormField>
                  <FastField
                    name="emailAddress"
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        inputComponent={
                          <TextInput
                            name={field.name}
                            type={TextFieldTypes.text}
                            value={values.emailAddress}
                            onChange={e => setFieldValue('emailAddress', e.target.value)}
                          />
                        }
                        error={errors?.emailAddress}
                        label="Recipient"
                      />
                    )}
                  />
                </FormField>
              </FormLoader>
            )
          }}
        </Formik>
      </div>
    </Modal>
  )
}

ResendEmailModal.propTypes = propTypes

export default ResendEmailModal
