import React, { useState } from 'react'
import { DimmerLoader, Modal, FormField, Checkbox, Button } from 'views/components'
import styles from './ConfirmDeleteApplicationModal.module.scss'

type Props = {
  party: string
  isOpen: boolean
  isLoading: boolean
  onDelete: () => void
  onCancel: () => void
}

const ConfirmDeleteApplicationModal = ({ party, isOpen, isLoading, onDelete, onCancel }: Props) => {
  const [isConfirmPartyNameChecked, setIsConfirmPartyNameChecked] = useState(false)

  return (
    <Modal className={styles.root} isOpen={isOpen} padding="sm" width="sm" theme="dark" showClose={false}>
      <Modal.Header title="Delete Application Confirmation" />
      <Modal.Body>
        <DimmerLoader loading={isLoading} bgColor="dark">
          <>
            <div className={styles['sub-header']}>
              <p>
                By deleting, reOS will remove this applicant from the lease and delete any related invoices. Please
                confirm you are happy to proceed by checking the applicant's name below:
              </p>
              <FormField>
                <Checkbox
                  name="confirmPartyName"
                  label={party}
                  checked={isConfirmPartyNameChecked}
                  onChange={(e: any) => setIsConfirmPartyNameChecked(e.checked)}
                />
              </FormField>
            </div>
          </>
        </DimmerLoader>
        <div className="Modal_Actions">
          <Button disabled={!isConfirmPartyNameChecked} key="Yes, accept" link onClick={onDelete}>
            Yes, delete
          </Button>
          <Button key="Cancel" link onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ConfirmDeleteApplicationModal
