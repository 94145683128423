import React, { useMemo } from 'react'
import { subscribe } from 'react-contextual'
import CreditNoteProvider from '../CreditNoteProvider'
import Modal from '../../../views/components/molecules/Modal/Modal'
import { InvoicesProvider } from '../../../views/providers'
import { CreditNoteForm } from '..'
import CurrencyText from '../../../views/components/atoms/Typography/CurrencyText/CurrencyText'

const CreditNoteModal = ({
  isOpen,
  invoiceId,
  closeCreditNote,
  currentInvoice,
  getPartyNameById,
  creditNoteReasons,
  createCreditNote,
  isSubmitting,
}: any) => {
  const beneficiaries = useMemo(
    () =>
      currentInvoice.beneficiaries.map(({ beneficiary, balance }: any) => ({
        beneficiaryId: beneficiary.value.id,
        name: getPartyNameById(beneficiary.value.partyId),
        reference: beneficiary.value.reference,
        balance,
      })),
    [currentInvoice, getPartyNameById],
  )

  return (
    <Modal isOpen={isOpen} width="lg" padding="md" onClose={closeCreditNote}>
      <h3 className="Modal_Content">
        Credit Note: INV-{currentInvoice.invoiceNumber} (<CurrencyText>{currentInvoice.balance}</CurrencyText>{' '}
        outstanding)
      </h3>
      <div className="Modal_Content">
        <CreditNoteForm
          invoiceId={currentInvoice.id}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ invoiceId: any; invoiceBalance: any; benef... Remove this comment to see the full error message
          invoiceBalance={currentInvoice.balance}
          beneficiaries={beneficiaries}
          creditNoteReasons={creditNoteReasons.map((r: any) => ({
            label: r.name,
            value: r.value,
          }))}
          createCreditNote={createCreditNote}
          isSubmitting={isSubmitting}
        />
      </div>
    </Modal>
  )
}

export default subscribe(
  [CreditNoteProvider, InvoicesProvider],
  (
    { isOpen, invoiceId, closeCreditNote, createCreditNote, creditNoteReasons, isSubmitting }: any,
    { currentInvoice, getPartyNameById }: any,
  ) => ({
    isOpen,
    invoiceId,
    closeCreditNote,
    createCreditNote,
    creditNoteReasons,
    isSubmitting,
    currentInvoice,
    getPartyNameById,
  }),
)(CreditNoteModal)
