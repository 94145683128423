import React, { useState, useEffect, useCallback } from 'react'
import cx from 'classnames'
import { OtpVerification, Modal, Select, Loader } from '../../../../views/components'
import { subscribe } from 'react-contextual'
import SupportStaffProvider from '../../SupportStaffProvider'
import styles from './SupportStaffLogin.module.scss'
import AgencySelect from '../AgencySelect/AgencySelect'

const SupportStaffLogin = (props: any) => {
  const {
    isAgencySelectionModalOpen,
    closeAgencySelectionModal,
    isValidSupportStaff,
    agencyListing,
    fetchAgencyListing,
    agencyLogin,
  } = props

  const initialState = { agency: null, otp: null }
  const [state, setState] = useState(initialState)

  const [agencyOptions, setAgencyOptions] = useState([])

  /**
   * Fetch agency listing & request OTP
   */
  useEffect(() => {
    if (state.agency === null && isValidSupportStaff && isAgencySelectionModalOpen) {
      fetchAgencyListing()
    }
  }, [state.agency, fetchAgencyListing, isValidSupportStaff, isAgencySelectionModalOpen])

  /**
   * Set agency Select options
   */
  useEffect(() => {
    if (agencyListing) {
      setAgencyOptions(
        agencyListing.map((a: any) => ({
          // only used for searching
          label: `${a.agencyName}${a?.tradingAs && `(${a.tradingAs})`}`,

          agencyName: a.agencyName,
          tradingAs: `Trading as: ${a?.tradingAs ? a.tradingAs : 'N/A'}`,
          value: a.agencyId,
        })),
      )
    }
  }, [agencyListing])

  /**
   * Handle agency login
   */
  useEffect(() => {
    if (state.agency !== null && state.otp !== null) {
      // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      agencyLogin({ ...state.agency, otp: state.otp })
    }
  }, [state.agency, state.otp, agencyLogin])

  const handleAgencySelection = useCallback(
    ({ value }) => {
      const agency = agencyListing.find((a: any) => a.agencyId === value)
      setState({ ...state, agency })
    },
    [setState, state, agencyListing],
  )

  const handleOtpSubmission = useCallback(
    ({ pin }) => {
      setState({ ...state, otp: pin })
    },
    [setState, state],
  )

  const handleClose = useCallback(() => {
    setState(initialState)
    closeAgencySelectionModal()
  }, [setState, initialState, closeAgencySelectionModal])

  if (!isValidSupportStaff || !isAgencySelectionModalOpen) {
    return null
  }

  const isLoading = state.agency !== null && state.otp !== null

  const classes = cx([styles.root], {
    [styles['is-loading']]: isLoading,
  })

  return (
    <div className={classes}>
      {isLoading && <Loader />}
      <Modal isOpen={state.agency === null} width="lg" onClose={handleClose}>
        <h3 className="Modal_Title">Support Login</h3>
        <div className="Modal_Content">
          <AgencySelect agencyOptions={agencyOptions} onAgencySelection={handleAgencySelection} />
          &nbsp;
        </div>
      </Modal>
      <OtpVerification
        isOpen={state.agency !== null && state.otp === null}
        onSubmit={handleOtpSubmission}
        onClose={handleClose}
      />
    </div>
  )
}

export default subscribe(
  [SupportStaffProvider],
  ({
    isValidSupportStaff,
    isAgencySelectionModalOpen,
    closeAgencySelectionModal,
    fetchAgencyListing,
    agencyListing,
    agencyLogin,
  }: any) => ({
    isValidSupportStaff,
    isAgencySelectionModalOpen,
    closeAgencySelectionModal,
    fetchAgencyListing,
    agencyListing,
    agencyLogin,
  }),
)(SupportStaffLogin)
