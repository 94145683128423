import React, { useCallback } from 'react'
import { userApiEvents, userApiSelectors } from 'modules/api/user'
import { userRoleOptions } from 'modules/api/user/constants'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button } from 'views/components'
import styles from './ConfirmUserRoleChange.module.scss'

type Props = {
  isOpen: boolean
  userId: string | null
  role: string | null
  onConfirm?: () => void
  onCancel: () => void
}

const ConfirmUserRoleChange = ({ isOpen, userId, role, onConfirm, onCancel }: Props) => {
  const dispatch = useDispatch()
  const agencyId = useSelector(userApiSelectors.getCurrentAgencyId)
  const user = useSelector(state => userApiSelectors.getTeamMemberByUserId(state)(userId))
  const selectedRole = userRoleOptions.find(option => option.value === role)

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm()
    }

    if (agencyId && userId && role) {
      dispatch(userApiEvents.changePermissions_request({ params: { userId }, body: { role, agencyId } }))
    }
  }

  return (
    <Modal className={styles.root} isOpen={isOpen} padding="sm" width="sm" theme="light" showClose={false}>
      <Modal.Header title="Change User Role" />
      <Modal.Body>
        <>
          <div className={styles['sub-header']}>
            <p>
              Are you sure you want to change{' '}
              {user?.emailAddress ? <strong>{user?.emailAddress}</strong> : `this user's`} role to{' '}
              <strong>{selectedRole?.label}</strong>?
            </p>
          </div>
        </>
        <div className="Modal_Actions">
          <Button key="Yes" link onClick={handleConfirm}>
            Yes
          </Button>
          <Button key="Cancel" link onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ConfirmUserRoleChange
