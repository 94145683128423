import React, { useEffect, useState } from 'react'

type TProps = {
  duration: number
}

const Timer = ({ duration }: TProps) => {
  const initialTime = duration
  const [timeRemaining, setTimeRemaining] = useState(initialTime)

  useEffect(() => {
    const timeInterval = setInterval(() => {
      setTimeRemaining(prevTime => {
        if (prevTime === 0) {
          clearInterval(timeInterval)
          return 0
        } else {
          return prevTime - 1
        }
      })
    }, 1000)

    return () => clearInterval(timeInterval)
  }, [])

  const minutes = Math.floor((timeRemaining % 3600) / 60)
  const seconds = timeRemaining % 60

  return (
    <span>
      {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
    </span>
  )
}

export default Timer
